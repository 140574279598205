import { useEffect } from 'react';
import {
  useAuth,
  useProfile,
  useLocalStorage,
  useAppState,
  familyCodeSelector,
} from 'hooks';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { JOIN_FAMILY } from 'gql';

const successToast = toastId => {
  toast.success('Code famille activé !', {
    id: toastId,
    duration: 5000,
  });
};

const errorToast = toastId => {
  toast.error("Impossible d'activer le code famille", {
    id: toastId,
    duration: 10000,
  });
};

function useFamilyCodeEffect() {
  const { isAuthenticated } = useAuth();
  const { refreshProfile } = useProfile();
  const [familyCode, actions] = useAppState(familyCodeSelector);
  const [familyCodeHydration, persist] = useLocalStorage('mio-family-code');

  const [addFamilyCode] = useMutation(JOIN_FAMILY);

  // hydration
  useEffect(() => {
    if (familyCodeHydration) actions.setFamilyCodeAction(familyCodeHydration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // persistence
  useEffect(() => {
    persist(familyCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyCode]);

  useEffect(() => {
    async function run() {
      const toastId = toast.loading("Activation de l'offre famille");
      try {
        await addFamilyCode({
          variables: {
            code: familyCode,
          },
        });

        actions.setFamilyCodeAction(null);
        successToast(toastId);
        await refreshProfile();
      } catch (error) {
        console.error(error);
        errorToast(toastId);
      }
    }

    if (isAuthenticated && familyCode) {
      console.log('RUN FAMILY CODE');
      run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, familyCode]);
}

export default useFamilyCodeEffect;
