import { CornerTag, InlineCard, Text } from 'design-system';
import React from 'react';
import { Link, useHistory as useBrowserHistory } from 'react-router-dom';
import { mdiInformationOutline, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import { ProgressBar } from 'UI';
import { getResizedUrl, withPixelDensity, dayjs } from 'utils';
import { POST_REMOVE_HISTORY } from 'gql';
import { useMutation } from 'hooks';
import classnames from 'classnames';

import './ContinueReadingBlock.scss';

export default function ContinueReadingBlock(props) {
  const { history, refetch, isCompleted, withCornerButtons, loading } = props;
  const { manga, chapter, page } = history;
  const browserHistory = useBrowserHistory();

  const [deleteHistory] = useMutation(POST_REMOVE_HISTORY);

  const handleOnPress = async mangaHistoryID => {
    await deleteHistory({
      variables: {
        mangaId: mangaHistoryID,
      },
    });
    refetch();
  };

  if (loading) return <InlineCard loading />;

  const isAvailable = dayjs(chapter.releaseDate).isBefore();
  const isNew =
    isAvailable &&
    !chapter.isRead &&
    dayjs(chapter.releaseDate).isAfter(dayjs().subtract(4, 'day'));

  return (
    <div
      className={classnames('ContinueReadingBlock', {
        notClickable: !isAvailable,
      })}
    >
      <InlineCard
        onClick={() => {
          if (!isAvailable) return;
          if (isCompleted) browserHistory.push(`/lire/${manga.slug}`);
          else
            browserHistory.push(
              `/lire/${manga.slug}/${chapter.number}/${page + 1}`
            );
        }}
        loading={loading}
        title={manga.title}
        picture={getResizedUrl(chapter.thumbnail.url, {
          width: withPixelDensity(82),
          height: withPixelDensity(118),
        })}
        subtitle={chapter.title}
        cornerTag={
          isNew ? (
            <CornerTag style={{ bottom: 0, right: 0 }} variant="primary">
              Nouveau chapitre
            </CornerTag>
          ) : null
        }
      >
        {withCornerButtons && (
          <div className="corner-buttons">
            <Link
              onClick={e => {
                e.stopPropagation();
              }}
              to={`/lire/${manga.slug}`}
              className="corner-button"
            >
              <Icon path={mdiInformationOutline} />
            </Link>
            <div
              className="corner-button"
              onClick={e => {
                e.stopPropagation();
                handleOnPress(manga._id);
              }}
            >
              <Icon path={mdiTrashCan} />
            </div>
          </div>
        )}
        <RepriseProgressBar history={history} />
        <ReleaseDateIndicator history={history} />
      </InlineCard>
    </div>
  );
}

const RepriseProgressBar = ({ history }) => {
  const { chapter, page, chapterIsFinished } = history;
  if (page === 0 || chapterIsFinished) return null;
  return (
    <div className="inline-progress">
      <ProgressBar
        completion={Math.ceil(((page + 1) * 100) / chapter.pageCount)}
      />
      <div className="subtitle">
        {page + 1}/{chapter.pageCount}
      </div>
    </div>
  );
};

const ReleaseDateIndicator = ({ history }) => {
  const { chapter } = history;
  const releaseDate = dayjs(chapter.releaseDate);
  if (releaseDate.isBefore()) return null;
  return (
    <Text size="small">Sortie le {releaseDate.format('dddd DD MMMM')}</Text>
  );
};
