import React from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import {
  mdiAccountCircle,
  mdiChevronRight,
  mdiGift,
  mdiCreditCardClockOutline,
  mdiDiscord,
  mdiHelpCircle,
  mdiLoginVariant,
  mdiBell,
  mdiCellphone,
  mdiAccountGroup,
} from '@mdi/js';

import { Text } from 'design-system';

import { FloatingMenu } from 'UI';
import { useAuth, useProfile, useResponsive } from 'hooks';
import { trackHandler } from 'utils';

const MainSlide = props => {
  const { navigation, flags } = props;
  const { logout, token } = useAuth();
  const { isChild } = useProfile();

  const { is } = useResponsive();
  const isDesktop = is('lg');

  return (
    <div className={classnames(props.className, 'slide')}>
      <FloatingMenu.Header>Compte</FloatingMenu.Header>
      <FloatingMenu.Body>
        <div
          className="row clickable"
          onClick={() => navigation.push('profile')}
        >
          <span className="settings-icon">
            <Icon path={mdiAccountCircle} />
          </span>
          <Text>Profil</Text>
          <span className="settings-icon">
            <Icon path={mdiChevronRight} />
          </span>
        </div>
        {flags.allowFamily && (
          <div
            className="row clickable"
            onClick={() => navigation.push('family')}
          >
            <span className="settings-icon">
              <Icon path={mdiAccountGroup} />
            </span>
            <Text>Ma famille</Text>
            <span className="settings-icon">
              <Icon path={mdiChevronRight} />
            </span>
          </div>
        )}
        {!flags.noProfile && (
          <div
            className="row clickable"
            onClick={() => navigation.push('notifications')}
          >
            <span className="settings-icon">
              <Icon path={mdiBell} />
            </span>
            <Text>Notifications</Text>
            <span className="settings-icon">
              <Icon path={mdiChevronRight} />
            </span>
          </div>
        )}
        {!flags.noProfile && !isChild && (
          <div
            className="row clickable"
            onClick={() => navigation.push('invitation')}
          >
            <span className="settings-icon">
              <Icon path={mdiGift} />
            </span>
            <Text>Parrainage</Text>
            <span className="settings-icon">
              <Icon path={mdiChevronRight} />
            </span>
          </div>
        )}
        {!flags.noProfile && !isChild && (
          <div
            className="row clickable"
            onClick={() => navigation.push('subscription')}
          >
            <span className="settings-icon">
              <Icon path={mdiCreditCardClockOutline} />
            </span>
            <Text>Abonnement</Text>
            <span className="settings-icon">
              <Icon path={mdiChevronRight} />
            </span>
          </div>
        )}
        {!flags.noProfile && !isChild && (
          <div
            className="row clickable"
            onClick={() => navigation.push('voucher')}
          >
            <span className="settings-icon">
              <Icon path={mdiGift} />
            </span>
            <Text>Carte cadeau</Text>
            <span className="settings-icon">
              <Icon path={mdiChevronRight} />
            </span>
          </div>
        )}
        <div className="row clickable" onClick={() => navigation.push('help')}>
          <span className="settings-icon">
            <Icon path={mdiHelpCircle} />
          </span>
          <Text>Aide</Text>
          <span className="settings-icon">
            <Icon path={mdiChevronRight} />
          </span>
        </div>

        {!isDesktop && (
          <a
            className="row clickable"
            target="_blank"
            rel="noopener noreferrer"
            href={`mangasio://sso?vendor=mangasio&token=${token}`}
            onClick={e => trackHandler(e, 'Open Mobile App Clicked')}
          >
            <span className="settings-icon">
              <Icon path={mdiCellphone} />
            </span>

            <Text>Utiliser l'application mobile</Text>
          </a>
        )}

        {!flags.noPromo && (
          <a
            className="row clickable"
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/975cZgh"
            onClick={e => trackHandler(e, 'Discord Clicked')}
          >
            <span className="settings-icon">
              <Icon path={mdiDiscord} />
            </span>

            <Text>Rejoindre notre discord</Text>
          </a>
        )}

        <div className="row clickable" onClick={logout}>
          <span className="settings-icon">
            <Icon path={mdiLoginVariant} />
          </span>
          <Text>Se déconnecter</Text>
        </div>
      </FloatingMenu.Body>
    </div>
  );
};

export default MainSlide;
