import React, { useState } from 'react';
import classnames from 'classnames';
import { Button, Text } from 'design-system';
import './FamilySlide.scss';

import { FloatingMenu } from 'UI';
import { useMutation, useProfile } from 'hooks';
import gql from 'graphql-tag';

const POST_REMOVE_CHILD = gql`
  mutation removeChild($code: UUID, $childId: ID) {
    removeChild(code: $code, childId: $childId)
  }
`;

const FamilyDeleteSlide = props => {
  const { navigation, className, member } = props;
  const [validationMessage, setValidationMessage] = useState(false);
  const [requestDeletion] = useMutation(POST_REMOVE_CHILD);
  const { refreshProfile } = useProfile();
  if (!member) return null;

  const handleOnPress = async e => {
    e.preventDefault();
    try {
      await requestDeletion({
        variables: {
          code: member.code,
          childId: member._id,
        },
      });
      setValidationMessage(!validationMessage);
      refreshProfile();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classnames(className, 'slide', 'family-slide')}>
      <FloatingMenu.Header className="clickable">
        {member.email}
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <div className="wrapper">
          <div className="delete-container">
            {validationMessage ? (
              <Text>
                <span className="delete-email">{member.email}</span> a bien été
                supprimé.e de ton abonnement Famille.
              </Text>
            ) : (
              <Text>
                Es-tu certain.e de vouloir supprimer le profil{' '}
                <span className="delete-email">{member.email}</span> de ton
                abonnement Famille ?
              </Text>
            )}
            <img
              className="coffeeImg"
              alt="coffee question"
              src={
                validationMessage
                  ? 'https://cdn.mangas.io/images/coffee/voucher-success-min.png'
                  : 'https://cdn.mangas.io/landing/coffee-question-mark.png'
              }
              width={150}
            />
          </div>
          <div style={{ padding: '0 15px', width: '100%' }}>
            <Button
              label={validationMessage ? 'Retour' : 'Confirmer la suppression'}
              variant="primary"
              fullWidth
              onClick={
                validationMessage
                  ? () => {
                      navigation.pop(2);
                      setTimeout(
                        () => setValidationMessage(!validationMessage),
                        1000
                      );
                    }
                  : handleOnPress
              }
            />
            {!validationMessage && (
              <Button
                style={{ marginTop: '15px' }}
                label="Retour"
                variant="secondary"
                fullWidth
                onClick={() => navigation.pop(2)}
              />
            )}
          </div>
        </div>
      </FloatingMenu.Body>
    </div>
  );
};

export default FamilyDeleteSlide;
