import PricingCardNew from 'Components/PricingCard/PricingCardNew';
import './PassCultureCard.scss';

export default function PassCultureCard() {
  return (
    <div className="pass-culture-container">
      <PricingCardNew
        className="pass-culture-card"
        title={
          <div className="logo">
            <img
              src="https://cdn.mangas.io/images/pass-culture-min.svg"
              alt="Logo du Pass Culture"
              width="230"
              height="78"
            />
          </div>
        }
        chipText="Pour les 18 ans et moins !"
        subtitle="Bénéficie gratuitement de ton abonnement Mangas.io !"
        link="https://passculture.app/recherche/resultats?query=%22mangas.io%22"
        linkText="Découvrir l’offre"
        version="passCulture"
      />
    </div>
  );
}
