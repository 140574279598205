import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Icon from '@mdi/react';
import {
  mdiCog,
  mdiArrowLeft,
  mdiMagnifyPlusOutline,
  mdiMagnifyMinusOutline,
} from '@mdi/js';

import { ReaderSettings } from 'Components';
import { FloatingMenu, Pill, BackLink } from 'UI';
import { useReaderSettings, useFlags } from 'hooks';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { dayjs } from 'utils';

import './LargeMenu.scss';

function SelectOptionPage(props) {
  const { innerProps, isDisabled, children, value } = props;
  return !isDisabled ? (
    <div {...innerProps} className="custom__option">
      <a href={`#page-${value}`}>{children}</a>
    </div>
  ) : null;
}

export default function LargeMenu(props) {
  const { zoom, setZoom, volumes, chapter, currentPage, slug } = props;
  const history = useHistory();
  const navigate = history.push;
  const [isOpen, setIsOpen] = useState(false);
  const [{ quality }] = useReaderSettings();
  const { flag: allowHDFlag } = useFlags('ALLOW_HD');

  const options = {
    reserveScrollBarGap: true,
  };

  const safeReaderQuality = allowHDFlag ? quality?.toUpperCase() : 'DEFAULT';

  useEffect(() => {
    if (isOpen) disableBodyScroll(null, options);
    else clearAllBodyScrollLocks();
    return clearAllBodyScrollLocks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div id="LargeMenu">
      <div className="reader-menu-content ">
        <BackLink
          className="reader-menu-block"
          fallback={`/lire/${slug}`}
          render={hasPreviousPage => (
            <>
              <Icon className="icon backIcon" path={mdiArrowLeft} />
              <span className="--text">Retour</span>
            </>
          )}
        />
        <div className="zoom">
          <Icon
            className="icon clickable"
            path={mdiMagnifyMinusOutline}
            onClick={() => zoom > 0.7 && setZoom(state => state - 0.1)}
          />
          <span className="--text">{Math.round(zoom * 100)}%</span>
          <Icon
            className="icon clickable"
            path={mdiMagnifyPlusOutline}
            onClick={() => zoom < 2 && setZoom(state => state + 0.1)}
          />
        </div>
        <div className="reader-menu-block chapter-select">
          <Select
            isSearchable={false}
            className="select chapter"
            classNamePrefix="custom"
            styles={{
              option: (provided, state) => {
                return {
                  ...provided,
                  opacity:
                    dayjs().isBefore(state.data.releaseDate) ||
                    state.data.isRead
                      ? 0.5
                      : 1,
                  pointerEvents: dayjs().isBefore(state.data.releaseDate)
                    ? 'none'
                    : 'all',
                };
              },
            }}
            value={{ value: chapter.number, label: chapter.title }}
            onChange={row => {
              navigate(`/lire/${slug}/${row.value}/1`);
            }}
            options={volumes.map(volume => ({
              label: `Tome ${volume.number}`,
              options: volume.chapters
                .filter(chapter => !chapter.isSeparator)
                .map(chapter => ({
                  value: chapter.number,
                  label: chapter.title,
                  isRead: chapter.isRead,
                  releaseDate: chapter.releaseDate,
                })),
            }))}
          />
        </div>
        <div className="reader-menu-block">
          <Select
            isSearchable={false}
            className="select page"
            classNamePrefix="custom"
            value={{
              value: parseInt(currentPage),
              label: `Page ${parseInt(currentPage) + 1}`,
            }}
            options={(chapter.pages || []).map(p => ({
              value: p.number,
              label: `Page ${p.number + 1}`,
            }))}
            components={{
              Option: SelectOptionPage,
            }}
          />
        </div>
        <div className="reader-menu-block settings">
          <div
            className="clickable settings-toggle"
            onClick={() => setIsOpen(!isOpen)}
          >
            <Icon className="icon" path={mdiCog} />
            {safeReaderQuality === 'HD' && (
              <Pill className="hd" label="HD" bgColor="#E11F7B" />
            )}
          </div>
          <FloatingMenu
            className="reader"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            withOverlay
          >
            <ReaderSettings />
          </FloatingMenu>
        </div>
      </div>
    </div>
  );
}
