import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import {
  mdiArrowLeft,
  mdiClock,
  mdiHelpCircleOutline,
  mdiTrashCan,
} from '@mdi/js';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'hooks';
import { Button, Disclaimer, Text } from 'design-system';
import './FamilySlide.scss';

import { FloatingMenu } from 'UI';
import Dropdown from 'Components/Dropdown/Dropdown';

const GET_PARENTAL_CONTROL_LEVELS = gql`
  query getParentalControls {
    parentalControlLevels {
      _id
      name
      description
    }
  }
`;

const UPDATE_PARENTAL_CONTROL_LEVEL = gql`
  mutation updateChildControl($childId: ID!, $controlLevelIds: [ID]!) {
    updateChildControl(childId: $childId, controlLevelIds: $controlLevelIds)
  }
`;

const FamilyMemberSlide = props => {
  const { navigation, className, familyMember } = props;

  const { data } = useQuery(GET_PARENTAL_CONTROL_LEVELS);
  const [updateParentalControlLevel] = useMutation(
    UPDATE_PARENTAL_CONTROL_LEVEL
  );

  const [controleParental, setControleParental] = useState(
    familyMember?.parentalControls?.name || 'Désactivé'
  );

  useEffect(() => {
    setControleParental(familyMember?.parentalControls[0]?.name || 'Désactivé');
  }, [familyMember]);

  const parentalControlLevels =
    data?.parentalControlLevels.map(level => ({
      value: level._id,
      label: level.name,
    })) || [];

  async function onChange(parentalControlLevel) {
    setControleParental(parentalControlLevel.name);
    await updateParentalControlLevel({
      variables: {
        childId: familyMember._id || familyMember.code,
        controlLevelIds: parentalControlLevel.value
          ? [parentalControlLevel.value]
          : [],
      },
    });
  }

  if (!familyMember) return null;

  return (
    <div className={classnames(className, 'slide', 'family-slide')}>
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        {familyMember.email}
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <div className="wrapper">
          {familyMember.status === 'PENDING' && (
            <div className="clear-line">
              <Disclaimer
                className="disclaimer-invitation"
                type="hint"
                icon={mdiClock}
                text={'Invitation en attente'}
              />
            </div>
          )}
          {familyMember.code && (
            <div className="clear-line">
              <Text>Code</Text>
              <Text>{familyMember.code}</Text>
            </div>
          )}

          <div className="clear-line">
            <div className="control-wrapper">
              <div className="control-top">
                <Text>Contrôle Parental</Text>
                {/* <Icon
                  path={mdiHelpCircleOutline}
                  size={'20px'}
                  color={'white'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => console.log('en savoir plus')}
                /> */}
              </div>
              <Dropdown
                selectDefaultValue={controleParental}
                selectOptions={[
                  {
                    value: null,
                    label: 'Désactivé',
                  },
                  ...parentalControlLevels,
                ]}
                notSearchable
                placeholder={controleParental}
                link={onChange}
              />
            </div>
          </div>

          <div className="delete-button">
            <Button
              label="Supprimer le profil"
              variant="secondary"
              fullWidth
              icon={<Icon path={mdiTrashCan} />}
              onClick={() =>
                navigation.push('family_delete', { member: familyMember })
              }
            />
          </div>
        </div>
      </FloatingMenu.Body>
    </div>
  );
};

export default FamilyMemberSlide;
