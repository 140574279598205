import React, { useState, useCallback, useEffect, useMemo } from 'react';
import gql from 'graphql-tag';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import {
  useQuery,
  useReaderSettings,
  useResponsive,
  useAuth,
  useProfile,
  getBundleAccess,
  useContext,
  useFlags,
} from 'hooks';
import {
  ReaderMenu,
  SEO,
  ChapterNotReleasedInsert,
  PayingAccessInsert,
  SignupInsert,
  SensitiveContentWall,
  UniversalReader,
  TouchReader,
  LtrContentWall,
  NotInBundlesInsert,
} from 'Components';
import { historyFragment } from 'gql';

import { dayjs, CustomErrorBoundary, track } from 'utils';

import './ReaderPage.scss';

const GET_CHAPTER_PAGES = gql`
  query getReadingChapter($slug: String, $chapterNb: Float) {
    manga(slug: $slug) {
      _id
      slug
      title
      description
      thumbnail {
        url
      }
      categories {
        label
      }
      contentWarning
      direction
      authors {
        _id
        name
      }
      publishers {
        publisher {
          _id
          name
        }
      }
      volumes {
        _id
        number
        description
        chapters {
          _id
          title
          number
          isRead
          isSeparator
          releaseDate
        }
      }
      chapter(number: $chapterNb) {
        _id
        number
        title
        releaseDate
        pageCount
        access
        copyright
        pages {
          _id
          isDoublePage
          number
          image {
            meta {
              width
              height
              ratio
            }
          }
        }
        next {
          _id
          title
          number
          releaseDate
          access
        }
        previous {
          _id
          title
          number
          pageCount
          releaseDate
          access
        }
        bundles
      }
    }
  }
`;

const PAGE_READ = gql`
  mutation pageReadEvent($payload: PageReadEventInput!, $clientId: ID) {
    pageRead(payload: $payload, clientId: $clientId) {
      ${historyFragment}
    }
  }
`;

function redirectIfWrongChapterNumber(slug, cNo, chapter) {
  if (isNaN(cNo) || !chapter) {
    console.info('wrong chap');
    return <Redirect to={`/lire/${slug}`} noThrow />;
  }
}

function redirectIfWrongPageNumber(slug, cNo, pNo) {
  if (isNaN(pNo)) {
    console.info('wrong page');
    return <Redirect to={`/lire/${slug}/${cNo}/1`} noThrow />;
  }
}

function previewWall(manga, chapter, isActive, isAuthenticated) {
  const isOpen = chapter.access === 'OPEN';
  const isFree = chapter.access === 'FREE';
  const isPaid = chapter.access === 'PAID';

  if (isOpen) return;
  if (!isAuthenticated && isFree)
    return <SignupInsert backUrl="/signup" manga={manga} />;
  if (!isActive && isPaid) {
    return (
      <PayingAccessInsert
        manga={manga}
        chapter={chapter}
        backUrl="/abonnement"
      />
    );
  }
}

function releaseWall(slug, chapter) {
  const today = dayjs();

  if (dayjs(chapter.releaseDate).isAfter(today))
    return (
      <ChapterNotReleasedInsert
        backUrl={`/lire/${slug}/`}
        releaseDate={chapter.releaseDate}
      />
    );
}

function startWall(slug, chapter, pageIndex) {
  if (pageIndex < 0 && chapter.number === 1) {
    // console.info('start wall redirection', chapter.number, { pageIndex });
    return <Redirect to={`/lire/${slug}/1/1`} noThrow />;
  }
}

function bundleWall(chapter, bundles) {
  const access = getBundleAccess(bundles, chapter.bundles);
  if (access === 'LIST' && chapter.access === 'PAID') {
    return <NotInBundlesInsert backUrl="/abonnement" />;
  }
}

function ReaderSelect(props) {
  const { is } = useResponsive();
  const isMobile = !is('lg');

  return isMobile ? <TouchReader {...props} /> : <UniversalReader {...props} />;
}

function ReaderPage(props) {
  const { slug, chapterNb, pageNb = 1 } = props;
  const [
    { quality, ignoreContentWarning, zoom },
    setSettings,
  ] = useReaderSettings();
  const { flag: allowHDFlag } = useFlags('ALLOW_HD');

  const safeReaderQuality = allowHDFlag ? quality?.toUpperCase() : 'DEFAULT';
  const { loading, data = {} } = useQuery(GET_CHAPTER_PAGES, {
    variables: {
      chapterNb: parseFloat(chapterNb),
      slug,
      quality: safeReaderQuality,
    },
    fetchPolicy: 'cache-and-network',
  });
  const history = useHistory();
  const navigate = history.push;
  const { profile } = useProfile();
  const { bundles } = profile;
  const { isAuthenticated, clientId } = useAuth();
  const [pageRead] = useMutation(PAGE_READ);
  const [pageIndex, setPageIndex] = useState(parseInt(pageNb) - 1);
  const setZoom = updateState => {
    var result = parseFloat(updateState(zoom).toFixed(1));
    setSettings({
      zoom: result,
    });
  };
  const { context: MangaContext } = useContext('MangaContext');

  const { manga = { chapter: { pages: [] }, volumes: [] } } = data;
  const { title, volumes, chapter, contentWarning, direction } = manga;
  const [displayAlert, setDisplayAlert] = useState(
    !ignoreContentWarning && contentWarning
  );

  const [displayLtrAlert, setDisplayLtrAlert] = useState(false);

  const currentVolume = volumes.find(volume =>
    volume.chapters.some(chapter => chapter.number.toString() === chapterNb)
  );

  useEffect(() => {
    if (manga?._id)
      track('Manga Opened', {
        manga_id: manga._id,
        title: manga.title,
        slug: manga.slug,
        description: manga.description,
        thumbnail: manga.thumbnail?.url,
        categories: manga.categories?.map(category => category.label),
        authors: manga.authors?.map(author => author.name),
        publishers: manga.publishers?.map(
          publisher => publisher.publisher?.name
        ),
        volume: currentVolume?.number,
        volumes: manga.volumes?.length,
        chapter: {
          id: manga.chapter?._id,
          number: manga.chapter?.number,
          title: manga.chapter?.title,
        },
      });
  }, [manga, currentVolume?.number]);

  useEffect(() => {
    window.history.replaceState(
      {},
      '',
      `/lire/${slug}/${chapterNb}/${pageIndex + 1}`
    );
  }, [chapterNb, pageIndex, slug]);

  useEffect(() => {
    setPageIndex(parseInt(pageNb) - 1);
  }, [pageNb]);
  useEffect(() => {
    setDisplayAlert(!ignoreContentWarning && contentWarning);
  }, [ignoreContentWarning, contentWarning]);

  useEffect(() => {
    if (direction === 'ltr') {
      setDisplayLtrAlert(true);
      setTimeout(() => {
        setDisplayLtrAlert(false);
      }, 3000);
    }
  }, [direction]);

  const sendPageReadEvent = useCallback(
    page => {
      if (chapter && manga && chapter._id && manga._id && !isNaN(page)) {
        const variables = {
          payload: {
            page,
            chapterId: chapter._id,
            mangaId: manga._id,
          },
          clientId: !isAuthenticated ? clientId : undefined,
        };
        pageRead({
          variables,
        });
      }
    },
    [chapter, clientId, isAuthenticated, manga, pageRead]
  );

  useEffect(() => {
    window.history.replaceState(
      {},
      '',
      `/lire/${slug}/${chapterNb}/${pageIndex + 1}`
    );
  }, [chapterNb, pageIndex, slug]);

  const onContextMenu = e => {
    if (!process.env.REACT_APP_ALLOW_RIGHT_CLICK) {
      return e.preventDefault();
    }
  };

  return useMemo(
    () => (
      <MangaContext.Provider value={manga}>
        <div id="ReaderPage" onContextMenu={onContextMenu}>
          {!loading && (
            <SEO
              title={`Lire chapitre ${chapterNb} de ${manga.title} en ligne - Scan vf officiel et légal - Mangas.io`}
              description={`Lecture en ligne du chapitre ${chapterNb} de ${
                manga.title
              } / ${manga.authors.length > 0 &&
                manga.authors[0].name} sur Mangas.io. ${
                currentVolume?.description
              }`}
            />
          )}
          {displayAlert && (
            <SensitiveContentWall
              back={() => navigate(`/lire/${slug}`)}
              dismiss={DisableSensitiveContentWall => {
                setSettings({
                  ignoreContentWarning: DisableSensitiveContentWall,
                });
                setDisplayAlert(false);
              }}
            />
          )}
          {!displayAlert && displayLtrAlert && (
            <LtrContentWall
              dismiss={() => {
                setDisplayLtrAlert(false);
              }}
            />
          )}
          {!loading &&
            (redirectIfWrongChapterNumber(slug, chapterNb, chapter) ||
              redirectIfWrongPageNumber(slug, chapterNb, pageNb) ||
              previewWall(manga, chapter, profile.isActive, isAuthenticated) ||
              releaseWall(slug, chapter) ||
              startWall(slug, chapter, pageIndex) ||
              bundleWall(chapter, bundles) || (
                <>
                  <ReaderMenu
                    title={title}
                    currentPage={pageIndex}
                    volumes={volumes || []}
                    chapter={chapter || { pages: [] }}
                    navigateTo={setPageIndex}
                    zoom={zoom}
                    setZoom={setZoom}
                    slug={slug}
                  />
                  <ReaderSelect
                    slug={slug}
                    manga={manga}
                    chapter={chapter}
                    direction={manga.direction || 'rtl'}
                    currentPage={pageIndex}
                    navigateTo={setPageIndex}
                    sendPageReadEvent={sendPageReadEvent}
                    zoom={zoom}
                  />
                </>
              ))}
        </div>
      </MangaContext.Provider>
    ),
    // eslint-disable-next-line
    [loading, manga, chapter, pageIndex, zoom, displayAlert, displayLtrAlert]
  );
}

// ReaderPage.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: 'ReaderPage',
// };

export default function ReaderBoundary(props) {
  const params = useParams();
  const handleError = error => {
    console.log(error);
    return <Redirect to={`/lire/${params.slug}`} />;
  };
  return (
    <CustomErrorBoundary handleError={handleError}>
      <ReaderPage {...params} />
    </CustomErrorBoundary>
  );
}
