import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/client';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline, mdiArrowLeft } from '@mdi/js';
import { Button, Disclaimer, Input, Text } from 'design-system';
import { FloatingMenu } from 'UI';
import { useMail, useProfile } from 'hooks';
import { ADD_FAMILY_MEMBER } from 'gql';
import './FamilySlide.scss';

const errorConfig = [
  {
    field: 'email',
    error: 'Variable "$email" got invalid value',
    userLabel: 'Email invalide',
  },
];

const FamilyAddSlide = props => {
  const { className, navigation } = props;
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [validationMessage, setValidationMessage] = useState(false);
  const { refreshProfile } = useProfile();
  const [addFamilyMember] = useMutation(ADD_FAMILY_MEMBER);
  const emailPlaceholder = useMail();

  function ErrorDisclaimers({ field }) {
    if (!errors) return null;
    return errors
      .filter(error => error.field === field)
      .map((error, index) => (
        <Fragment key={index}>
          <Disclaimer
            type="danger"
            size="large"
            icon={mdiAlertCircleOutline}
            text={error.userLabel}
          />
        </Fragment>
      ));
  }

  function ValidationDisclaimer() {
    if (!validationMessage) return null;
    if (errors.length > 0) return null;
    return (
      <Fragment>
        {validationMessage && (
          <Disclaimer
            type="success"
            size="large"
            icon={mdiAlertCircleOutline}
            text={"L'invitation a bien été envoyée !"}
          />
        )}
      </Fragment>
    );
  }

  const handleOnPress = async () => {
    try {
      await addFamilyMember({
        variables: {
          email: email,
        },
      });
    } catch (e) {
      const errors = errorConfig.filter(config =>
        e.message.includes(config.error)
      );
      setErrors(errors);
    }
    setValidationMessage(true);
    refreshProfile();
  };

  return (
    <div className={classnames(className, 'slide', 'family-slide')}>
      <FloatingMenu.Header
        className="clickable"
        onClick={() => {
          navigation.pop();
          setErrors([]);
          setValidationMessage(false);
          setEmail('');
        }}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        Ajout de membre
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <div className="wrapper">
          <div className="add-member">
            <ErrorDisclaimers field="email" />
            <ValidationDisclaimer />
            <Text>
              Entre l'email du / de la bénéficiaire. Une invitation lui sera
              envoyée à cette adresse !
            </Text>
            <Input
              field="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              label="E-mail"
              type="email"
              placeholder={emailPlaceholder}
              required
            />
            <Button
              fullWidth
              label={validationMessage ? 'Retour' : 'Valider'}
              variant="secondary"
              onClick={
                validationMessage
                  ? () => {
                      navigation.pop();
                      setValidationMessage(false);
                      setErrors([]);
                      setEmail('');
                    }
                  : handleOnPress
              }
            />
          </div>
        </div>
      </FloatingMenu.Body>
    </div>
  );
};

export default FamilyAddSlide;
