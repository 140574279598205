import gql from 'graphql-tag';

export const ADD_FAMILY_MEMBER = gql`
  mutation requestFamilyInvitation($email: EmailAddress!) {
    requestFamilyInvitation(email: $email) {
      _id
      code
      status
      email
      url
    }
  }
`;
