import React, { useEffect } from 'react';
import AOS from 'aos';
import Rellax from '../../assets/js/rellax/rellax.js';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';
import { mdiCrownOutline } from '@mdi/js';
import 'aos/dist/aos.css';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks';
import { Button } from 'design-system';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import {
  LandingFaq,
  LandingUsersFeedback,
  LandingFeatures,
  LandingPartners,
  LandingHero,
  TitleHeader,
  LandingSupportCoffee,
  FlashMessage,
  Footer,
  LandingCards,
  PublishersBloc,
  PassCultureCard,
  TrendingLine,
  PricingTables,
} from 'Components';
import metrics from 'utils/metrics.js';
import { AppStoreButtons } from 'UI';
import { useAppState, skipLandingSelector } from 'hooks';

import './LandingPage.scss';

export default function LandingPage({ noindex = false }) {
  const [skipLanding, actions] = useAppState(skipLandingSelector);
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  useEffect(() => {
    AOS.init({
      once: true,
      anchorPlacement: 'top-center',
    });

    new Rellax('.rellax', {
      breakpoints: [576, 1468, 2501],
    });

    document.querySelector('html').classList.add('smooth');

    return () => {
      document.querySelector('html').classList.remove('smooth');
    };

    /*eslint-disable-next-line*/
  }, []);

  return (
    <>
      <LandingHero
        tags={[
          'Lecture illimitée',
          'Des milliers de tomes',
          `${metrics.readers}+ passionné.e.s`,
        ]}
        title="Tes mangas en ligne légalement et sans pub"
        subtitle="Lis tes mangas à l'abonnement et retrouve de nouveaux chapitres disponibles chaque semaine dès 6,99€ par mois."
      >
        <div className="headerButtons">
          <Link to="/signup">
            <Button
              size="large"
              variant="gold"
              label="Activer mon essai gratuit"
              icon={<Icon path={mdiCrownOutline} />}
              id="cta_landing_access"
              data-aos="fade-up"
              data-aos-delay="300"
            />
          </Link>
          <Link to="/">
            <Button
              size="small"
              variant="text"
              label="Commencer à lire gratuitement"
              id="cta_landing_signup"
              data-aos="fade-up"
              data-aos-delay="300"
              onClick={e => {
                if (!skipLanding) {
                  e.preventDefault();
                  actions.setSkipLandingAction(true);
                }
              }}
            />
          </Link>
        </div>
        <AppStoreButtons passCulture data-aos="fade-up" data-aos-delay="300" />
      </LandingHero>

      <div id="LandingPage">
        {noindex && (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}
        <Helmet
          script={[
            helmetJsonLdProp({
              '@context': 'http://schema.org',
              '@type': 'FAQPage',
              mainEntity: [
                {
                  '@type': 'Question',
                  name: 'Comment lire ses mangas en ligne ?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text:
                      "Il existe plusieurs solutions légales pour lire tes mangas en ligne ! Il est possible d'acheter des mangas numériques à l'unité mais cette solution devient rapidement coûteuse... C'est pourquoi nous avons lancé Mangas.io et son modèle à l'abonnement : pour le prix d'un manga par mois, nous te proposons des milliers de chapitres à lire en illimité !",
                  },
                },
                {
                  '@type': 'Question',
                  name: 'Pourquoi lire ses mangas légalement ?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text:
                      'Tout simplement pour soutenir les artistes qui te font rêver au quotidien ! Pour chacune de tes lectures, nous rémunérons tes mangakas préférés ! Notre plateforme s’engage à mettre en place un modèle de rémunération juste, viable et efficace pour les auteur.ice.s et les éditeurs. Il est temps de réconcilier les intérêts des lecteur.ice.s et des créateur.ice.s !',
                  },
                },
              ],
            }),
          ]}
        />
        <Helmet
          script={[
            helmetJsonLdProp({
              '@context': 'http://schema.org',
              '@type': 'Organization',
              name: 'Mangas.io',
              url: 'https://www.mangas.io/',
              logo: 'https://www.mangas.io/favicon_180.png',
              slogan: 'Tes mangas en ligne légalement et sans pub !',
              description:
                "Mangas.io est une plateforme légale de lecture de mangas en ligne. Pour le prix d'un manga par mois, tu accèdes à des milliers de chapitres à lire en illimité et sans pub.",
            }),
          ]}
        />

        <FlashMessage
          location="landing"
          render={children => (
            <section id="flash-message-landing">{children}</section>
          )}
        />
        <div data-aos="fade-up" data-aos-delay="300">
          <PublishersBloc />
        </div>

        <div className="popularConnected-line">
          <TitleHeader
            title="Des milliers de mangas à l’abonnement"
            subtitle="Voici nos mangas les plus populaires :"
          />
          <TrendingLine />
          <Link className="buttonLink" to={`/manga-en-ligne`}>
            <Button
              id="button"
              size="large"
              variant="primary"
              label="Voir notre catalogue complet"
            />
          </Link>
        </div>

        <LandingUsersFeedback />

        <TitleHeader
          title="L’app ultime de lecture pour les fans de mangas !"
          subtitle="Découvre nos meilleurs titres gratuitement 👇"
        />
        <LandingFeatures />

        <TitleHeader
          title="L’abonnement au prix d’un manga par mois"
          subtitle="Nos offres d’abonnement en ligne (disponibles aussi sur pass Culture)"
        />
        <PricingTables
          onClick={() =>
            history.push(isAuthenticated ? '/abonnement' : '/signup')
          }
        />
        <PassCultureCard />

        <TitleHeader
          title="Nos cartes cadeaux à offrir 🎁"
          subtitle="Un cadeau manga à petit prix ! "
        />
        <LandingCards />

        <LandingFaq title="Questions fréquentes" page_name="faq_landing" />

        <LandingSupportCoffee />

        <LandingPartners />
      </div>
      <Footer />
    </>
  );
}
