import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Switch, MemoryRouter } from 'react-router-dom';

import { Input, Button, Text } from 'design-system';

import { CaptiveNavbar, LandingHero, Success } from 'Components';

import { getResizedUrl, metrics, PublicRoute, withPixelDensity } from 'utils';
import {
  useAuth,
  useAppState,
  useProfile,
  BrowserHistoryProvider,
  useBrowserHistory,
} from 'hooks';

import { OnboardingBackground } from 'UI';

import '../Landing/LandingPage.scss';
import '../Redeem/RedeemPage.scss';
import { JOIN_FAMILY, VALIDATE_FAMILY_INVITE } from 'gql';
import { useMutation } from '@apollo/client';
import './JoinFamily.scss';

const errors = {
  INVALID_STATUS_SEQUENCE: "Impossible d'activer ce code sur votre compte",
  INVALID_FAMILY_CODE: 'Code invalide',
  FAMILY_CODE_EXPIRED: 'Le code a expiré',
  INVALID_PLAN: 'Le compte qui vous a invité n’a pas d’abonnement family actif',
  default: 'Une erreur est survenue',
};

function getErrorMessage(error) {
  if (errors[error]) return errors[error];
  return errors.default;
}

function RedeemPageJoinFamily({ code = '' }) {
  const { isAuthenticated } = useAuth();
  const [currentCode, setCode] = useState(code);
  const [disabled, setDisabled] = useState(currentCode.length === 0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [, actions] = useAppState();
  const history = useHistory();
  const browserHistory = useBrowserHistory();
  const { refreshProfile } = useProfile({
    subscription: true,
  });
  const [addFamilyCode] = useMutation(JOIN_FAMILY);
  const [validateFamilyCode] = useMutation(VALIDATE_FAMILY_INVITE);

  useEffect(() => {
    setDisabled(currentCode.length === 0);
  }, [currentCode]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!isAuthenticated) {
        await validateFamilyCode({ variables: { code: currentCode } });
        actions.setFamilyCodeAction(currentCode);
        browserHistory.push('/signup');
      } else {
        await addFamilyCode({ variables: { code: currentCode } });
        actions.setFamilyCodeAction(null);
        await refreshProfile();
        history.push('/success');
      }
    } catch (e) {
      setError(getErrorMessage(e.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="Landing" className="RedeemPage joinFamily">
      <CaptiveNavbar
        cta={
          <Button
            variant="primary"
            label="Activer l’offre"
            loading={loading}
            disabled={disabled}
            onClick={!disabled ? handleSubmit : undefined}
          />
        }
      />
      <header>
        <LandingHero
          tags={[
            'Sans engagement',
            'Pas de publicité',
            `${metrics.readers}+ utilisateur.ice.s`,
          ]}
          customImg={
            <img
              src={getResizedUrl(
                'https://cdn.mangas.io/landing/landing-family-bg.png',
                {
                  width: withPixelDensity(700),
                  height: withPixelDensity(500),
                }
              )}
              width="700"
              alt=""
            />
          }
          customHeader={
            <div>
              <img
                src={'https://cdn.mangas.io/landing/familymiologo.png'}
                width="250"
                alt={'Mio Family Logo'}
              />
              <img
                style={{ marginLeft: '20px' }}
                src={'https://cdn.mangas.io/landing/avatars-family.png'}
                width="250"
                alt={'Mio Family Logo'}
              />
            </div>
          }
          title="Rejoins ta famille !"
          subtitle="Entre ici le code Family que tu as reçu dans ton invitation pour accéder à l'abonnement."
        >
          <div className="code-validation-input">
            <Input
              value={currentCode}
              onChange={e => setCode(e.target.value)}
              placeholder="Ton code Family"
              highlight={error ? 'error' : null}
              helper={error}
            />
            <Button
              label="Activer"
              variant="primary"
              size="large"
              loading={loading}
              disabled={disabled}
              onClick={!disabled ? handleSubmit : undefined}
            />
          </div>
        </LandingHero>
      </header>
    </div>
  );
}

function RedeemSuccess({ onClick }) {
  const history = useBrowserHistory();
  return (
    <OnboardingBackground id="mio-loginPage">
      <Success>
        <Text bold>Félicitations !</Text>
        <Text>Ton code a bien été activé. Bonne lecture sur Mangas.io !</Text>
        <Button
          fullWidth
          className="divider"
          variant="primary"
          label="Continuer"
          onClick={() => history.push('/')}
        />
      </Success>
    </OnboardingBackground>
  );
}

export default function RedeemPageJoinFamilyRouter() {
  const { code = '' } = useParams();
  return (
    <BrowserHistoryProvider>
      <MemoryRouter>
        <Switch>
          <PublicRoute path="/success" component={<RedeemSuccess />} />
          <PublicRoute
            path="/"
            component={<RedeemPageJoinFamily code={code} />}
          />
        </Switch>
      </MemoryRouter>
    </BrowserHistoryProvider>
  );
}
