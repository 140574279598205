import React from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiChevronRight } from '@mdi/js';
import { Pill, Text } from 'design-system';
import { FloatingMenu } from 'UI';
import { useProfile } from 'hooks';
import { range, textEllipsis } from 'utils';
import './FamilySlide.scss';

function FamilyMembers({ familyMembers, navigation, profile }) {
  return (
    <>
      <div className="line">
        <Text>{textEllipsis(profile.email, 20)}</Text>
        <Pill backgroundColor={'#646066'}>compte principal</Pill>
      </div>
      {familyMembers.length > 0 && (
        <>
          {familyMembers.map((member, index) => (
            <div
              key={index}
              className="family-member line"
              onClick={() =>
                navigation.push('family_member', {
                  familyMember: member,
                })
              }
            >
              <Text>{member.email}</Text>
              <Icon path={mdiChevronRight} size={'24px'} color={'white'} />
            </div>
          ))}
        </>
      )}
      <>
        {range(3 - (familyMembers?.length || 0)).map(index => (
          <div
            key={index}
            className="family-member line"
            onClick={() => navigation.push('family_add')}
          >
            <Text style={{ opacity: 0.5 }}>Emplacement libre</Text>
            <Icon path={mdiChevronRight} size={'24px'} color={'white'} />
          </div>
        ))}
      </>
    </>
  );
}
const FamilySlide = props => {
  const { navigation, className } = props;
  const { profile } = useProfile();
  if (!profile) return null;

  return (
    <div className={classnames(className, 'slide', 'family-slide')}>
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        Ma famille
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <div className="wrapper">
          <FamilyMembers
            profile={profile}
            familyMembers={profile.children}
            navigation={navigation}
          />
        </div>
      </FloatingMenu.Body>
    </div>
  );
};

export default FamilySlide;
